
<template>
  <div v-bind:class="{'popup-as-form': editMode, 'popup-as-view':  viewMode}">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            A/S {{ i18n.look }}
          </span>
        </h3>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <b-form-group v-if="viewMode">
          <div class="d-flex flex-wrap">
            <!--begin::등록자-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.registrant}}{{i18n.sw}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ memberNm }}</span>
              </div>
            </div>
            <!--begin::등록 일시-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.registeredDate}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ getTimeFormat(createDt) }}</span>
              </div>
            </div>
            <!--begin::회사 이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.corporateName}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ companyNm }}</span>
              </div>
            </div>
            <!--begin::팜이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.farmName}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ farmNm }}</span>
              </div>
            </div>
            <!--begin::등록자 연락처-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">{{i18n.contact}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ getPhoneFormat(phoneNo) }}</span>
              </div>
            </div>
            <!--begin::등록자 이메일-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">{{i18n.email}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ memberEmail }}</span>
              </div>
            </div>
            <!--begin::처리 단계-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.progressStatus}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ claimSt }}</span>
              </div>
            </div>
            <!--begin::AS요청 유형-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.type}}</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ claimType }}</span>
              </div>
            </div>
            <!--begin::처리 담당자-->
            <label v-if="isProcessing" class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-success"></span>
              <span class="text">{{i18n.whoIsInChargeOfProcess}}</span>
            </label>
            <div v-if="isProcessing" class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">{{ regNm }}</span>
                </div>
              </div>
            </div>

            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">{{i18n.title}}</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">{{ title }}</span>
                </div>
              </div>
            </div>

            <label class="d-flex bullet-label cw-15 align-items-start">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">{{i18n.content}}</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">{{ content }}</span>
                </div>
              </div>
            </div>

            <!--begin::첨부 파일-->
            <template v-for="(file, idx) in attachFiles">
              <label class="d-flex bullet-label cw-15" :key="idx">
                <span v-if="idx === 0" class="bullet bullet-bar bg-dark"></span>
                <span class="text">{{ idx === 0 ? i18n.attachment : '' }}</span>
              </label>
              <div class="cw-85" :key="'name' + idx">
                <div class="pr-14 cw">
                  <div class="form-text d-flex align-items-center">
                    <span class="text">{{ file.name }}</span>
                    <button type="button" class="btn btn-icon btn-primary btn-sm ml-4" @click="downloadFile(file.path)">
                      <i class="la la-download"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="file.isImg" :key="'img' + idx" style="width: 100%">
                <div class="pr-14 cw text-center">
                  <img :src="file.path" style="max-height: 400px; max-width: 100%;" />
                </div>
              </div>
            </template>

            <!--end::첨부 파일-->
            <label v-if="isProcessed" class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.whoProcessedTheClaim}}</span>
            </label>
            <div v-if="isProcessed" class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ answerNm }}</span>
              </div>
            </div>

            <label v-if="isProcessed" class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.processedDate}}</span>
            </label>
            <div v-if="isProcessed" class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">{{ getTimeFormat(answerDt) }}</span>
              </div>
            </div>

            <label v-if="isProcessed" class="d-flex bullet-label cw-15 align-items-start">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.processContent}}</span>
            </label>
            <div v-if="isProcessed" class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">{{ answerContent }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button v-if="claimStDcd === '1016003' && isMine" class="mr-3" variant="primary" size="lg" @click="confirmClaim" v-bind:class="{'spinner spinner-light spinner-right': loaded}"><span class="">{{i18n.confirm}}</span></b-button>
        <b-button variant="secondary" size="lg" @click="goBack"><span class="">{{i18n.back}}</span></b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {getItem, getResult, isSuccess, lengthCheck, phoneFormat, timestampToDate} from '@/core/services/funcs';
import {ACT_GET_CLAIM, ACT_UPDATE_MY_CLAIMS_CONFIRM} from '@/core/services/variable';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'AsView',
  components: {},
  beforeMount() {
    this.boardNo = parseInt(this.$route.params.boardNo || 0)
    this.viewMode = true;

    this.$store.dispatch(ACT_GET_CLAIM, this.boardNo).then(resp => {
      if(lengthCheck(resp)){
        const item = getItem(resp);
        this.keys.forEach(key => {
          if(item[key]) this[key] = item[key];
        });

        for(let i = 1; i < 6; i++){
          if(this['attachFileNm' + i] && this['attachFilePath' + i]){
            const pathSplit = this['attachFilePath' + i].split('.');
            if(pathSplit.length > 0){
              const ext = pathSplit[pathSplit.length - 1];
              this.attachFiles.push({
                name: this['attachFileNm' + i],
                path: this['attachFilePath' + i],
                isImg: (this.extensionImage.indexOf(ext) > -1),
              });
            }
          }
        }
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'A/S', 'icon': 'fas fa-smile-beam' }]);
    window.scrollTo(0,0);
  },
  computed: {
    ...mapGetters(['myMno', 'myNm', 'myEmail', 'myPhoneNo', 'companyNm', 'farmNm', 'farmId']),
    isMine(){
      return (this.myMno === this.targetMno);
    },
    isProcessing(){
      return (this.claimStDcd && this.claimStDcd !== '1016001');
    },
    hasFile(){
      return this.attachFiles && this.attachFiles.length > 0;
    },
    isProcessed(){
      return (this.claimStDcd && (this.claimStDcd === '1016003' || this.claimStDcd === '1016004'));
    }
  },
  data() {
    return {
      keys: [
        'boardNo', 'targetMno', 'memberNm','memberEmail','createDt','phoneNo','regNm','regPhoneNo','regEmail',
        'answerYn','answerNm','answerContent','answerDt','claimStDcd','claimSt','claimTypeDcd','claimType',
        'title','content','attachFileNm1','attachFilePath1', 'attachFileNm2','attachFilePath2', 'attachFileNm3','attachFilePath3',
        'attachFileNm4','attachFilePath4', 'attachFileNm5','attachFilePath5'
      ],
      boardNo: 0,
      viewMode: false,
      editMode: false,
      boardGbText: '',

      // 정보
      targetMno: '',
      memberNm: '',
      memberEmail: '',
      createDt: null,
      phoneNo: '',
      regNm: '',
      regPhoneNo: '',
      regEmail: '',
      answerYn: 'N',
      answerNm: '',
      answerContent: '',
      answerDt: null,
      claimStDcd: '',
      claimSt: '',
      claimTypeDcd: '',
      claimType: '',
      title: '',
      content: '',
      attachFileNm1: '',
      attachFilePath1: '',
      attachFileNm2: '',
      attachFilePath2: '',
      attachFileNm3: '',
      attachFilePath3: '',
      attachFileNm4: '',
      attachFilePath4: '',
      attachFileNm5: '',
      attachFilePath5: '',
      extensionImage : ['bmp', 'dib', 'jpg', 'jpeg', 'jpe', 'gif', 'png'],
      attachFiles: [],

      loaded: false,
      i18n: {
        look: i18n.t('claim.look'),
        registrant: i18n.t('claim.registerClaim.registrant'),
        registeredDate: i18n.t('claim.registeredDate'),
        whoIsInChargeOfProcess: i18n.t('claim.detail.whoIsInChargeOfProcess'),
        progressStatus: i18n.t('claim.progressStatus'),
        contact: i18n.t('claim.registerClaim.contact'),
        email: i18n.t('claim.registerClaim.email'),
        corporateName: i18n.t('claim.registerClaim.corporateName'),
        farmName: i18n.t('claim.registerClaim.farmName'),
        type: i18n.t('claim.registerClaim.type'),
        title: i18n.t('claim.registerClaim.title'),
        content: i18n.t('claim.registerClaim.content'),
        back: i18n.t('claim.registerClaim.back'),
        whoProcessedTheClaim: i18n.t('claim.detail.whoProcessedTheClaim'),
        processContent: i18n.t('claim.detail.processContent'),
        processedDate: i18n.t('claim.detail.processedDate'),
        attachment: i18n.t('claim.registerClaim.attachment'),
        claimConfirmed: i18n.t('claim.registerClaim.claimConfirmed'),
        claimConfirmAsk: i18n.t('claim.registerClaim.claimConfirmAsk'),
        error: i18n.t('claim.registerClaim.error'),
        confirm : i18n.t('claim.registerClaim.confirm'),
      }
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    },
    getTimeFormat(timeStamp){
      if(timeStamp) return timestampToDate(timeStamp);
      return '-';
    },
    getPhoneFormat(phoneNo){
      if(phoneNo && phoneNo.trim()){
        return phoneFormat(phoneNo)
      }
      return '-';
    },
    downloadFile(attachFilePath){
      window.open(attachFilePath);
    },
    confirmClaim(){
      if(this.loaded) return;
      this.loaded = true

      const yesCallback = () => {
        this.$store.dispatch(ACT_UPDATE_MY_CLAIMS_CONFIRM, this.boardNo)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.claimConfirmed, color: 'success'});
            // 성공시 페이지 이동
            this.$router.push('/smartfarm/' + this.farmId + '/as' );
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.loaded = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.claimConfirmAsk},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });

    }
  }
};
</script>
